@import url('https://fonts.googleapis.com/css?family=VT323');
.TypingTerminal {
  font-family: 'VT323', monospace;
  background-color: #003B00;
  padding: 1rem 2rem;
  white-space: pre-wrap;
  text-align: left;
  font-size: 0.5rem;
  margin-top: 2rem;
  /* width: 75%; */
  min-height: 7rem;
}

.TypingTerminal > h1 {
  color: #00FF41;
}

#cursor {
  border-left: .1em solid #00FF41;
  animation: blink .7s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media only screen and (min-width: 600px) {
  .TypingTerminal {
    width: 400px;
    margin-left: 2rem;
    margin-right: auto;
  }
}
